/* global SentryEnvironment, SentryRelease, SentryUserData */

import {init, configureScope} from '@sentry/browser';

if (process.env.NODE_ENV === 'production') {
    init({
        dsn: 'https://c791a73cda4e451f961a8df94d8b7ec7@sentry.cosmocode.de/20',
        environment: window.SentryEnvironment,
        release: window.SentryRelease,
    });

    const user = window.SentryUserData ? {...SentryUserData} : null;

    if (user) configureScope(scope => scope.setUser(user));
}
